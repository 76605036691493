import { ApiAxios } from './repository.config';

const RESOURCE = '/pay';
const RESOURCE_USERS = '/users';

export default {
  async createUserForPayment(user_email, user_lang) {
    const { data } = await ApiAxios().post(`${RESOURCE_USERS}/create`, {
      user_email: user_email,
      user_lang: user_lang
    });
    return data;
  },

  async processPayment(dataPayment) {
    const { data } = await ApiAxios().post(`${RESOURCE}/process-payment`, {
      ...dataPayment
    });
    return data;
  },

  async verificationPayment(payment_id, paymentMethod_id) {
    const { data } = await ApiAxios().put(`${RESOURCE}/varification-payment`, {
      payment_id,
      paymentMethod_id
    });
    return data;
  }
};
