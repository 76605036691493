import payRepository from '@/repository/pay.repository';

//const timer = time => new Promise(resolve => setTimeout(resolve, time));

const state = {
  email_to_register: '',
  user_id: null
};

const getters = {
  email_to_register: state => state.email_to_register,
  iframe_link: state => state.iframe_link,
  iframe_psd2: state => state.iframe_psd2,
  blue_snap_token: state => state.blue_snap_token
};

const actions = {
  async createUser({ state, commit, rootGetters }) {
    const { user_id } = await payRepository.createUserForPayment(
      state.email_to_register,
      rootGetters['configWebStore/getLanguageWeb'].toUpperCase()
    );
    commit('setUserId', user_id);
  },

  async intentPayment({ state, rootGetters }, paymentMethod_id) {
    const user_id = state.user_id;
    const product_id = rootGetters['productStore/getIdProduct'];
    const dataPayment = {
      user_id,
      product_id,
      paymentMethod_id
    };
    try {
      const { status, result } = await payRepository.processPayment(dataPayment);
      return { status, result };
    } catch (e) {
      return { error: true };
    }
  },

  async verificationPayment({ state, rootGetters }, { payment_id, paymentMethod_id }) {
    try {
      const { status, result } = await payRepository.verificationPayment(payment_id, paymentMethod_id);
      return { status, result };
    } catch (e) {
      return { error: true };
    }
  }
};

const mutations = {
  setEmailPayment(state, email) {
    state.email_to_register = email;
  },
  setUserId(state, user_id) {
    state.user_id = user_id;
  }
};

export const paymentStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
