const excludeThisCountries = [
  'AR',
  'BO',
  'BR',
  'CL',
  'CO',
  'CR',
  'CU',
  'EC',
  'SV',
  'GY',
  'GD',
  'GT',
  'HT',
  'HN',
  'JM',
  'MX',
  'NI',
  'PY',
  'PA',
  'PE',
  'PR',
  'DO',
  'SR',
  'UY',
  'VE',
  'AO',
  'DZ',
  'BJ',
  'BW',
  'BF',
  'BI',
  'CV',
  'CM',
  'TD',
  'KM',
  'CI',
  'EG',
  'ER',
  'ET',
  'GA',
  'GM',
  'GH',
  'GN',
  'GW',
  'GQ',
  'KE',
  'LS',
  'LR',
  'LY',
  'MG',
  'MW',
  'ML',
  'MA',
  'MU',
  'MR',
  'MZ',
  'NA',
  'NE',
  'NG',
  'CF',
  'CD',
  'CG',
  'RW',
  'EH',
  'ST',
  'SN',
  'SC',
  'SL',
  'SO',
  'SZ',
  'ZA',
  'SD',
  'TZ',
  'TG',
  'TN',
  'UG',
  'DJ',
  'ZM',
  'ZW'
];

export default {
  excludeThisCountries
};
